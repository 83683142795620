/* login */
.login_Input {
  border-radius: 10px !important;
  height: 60px !important;
  align-items: center;
}

.login_Input:hover {
  border-color: #6fb28d !important;
}

.login_Input:focus {
  box-shadow: 0 0 0 2px #6fb28d33;
}

.login_Input.ant-input-affix-wrapper-focused,
.login_Input.ant-input-affix-wrapper:hover,
.login_Input.ant-input-affix-wrapper:focus {
  border-color: #6fb28d !important;
  border-right-width: 1px !important;
}

.login_Input.ant-input-affix-wrapper-focused,
.login_Input.ant-input-affix-wrapper:focus {
  box-shadow: 0 0 0 2px #6fb28d33;
}

.login_phone_input .ant-input {
  border-color: #d9d9d9 !important;
  border-right-width: 1px !important;
  box-shadow: unset !important;
}

.login_phone_input .ant-input:hover {
  border-color: #6fb28d !important;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px #6fb28d33;
}

.login_btn {
  background: #6fb28d !important;
  color: #fff !important;
  border-color: #6fb28d !important;
}

.login-card .ant-input-affix-wrapper-rtl {
  direction: ltr !important;
}

p.font-calss {
  font-family: 'kurdish_bold' !important;
}

.second_login_btn::placeholder,
.login_phone_input input::placeholder,
.login_Input input::placeholder {
  font-family: 'kurdish_regular' !important;
}

.ant-input-suffix {
  margin-right: 10px;
}